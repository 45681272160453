import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ item, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    boxShadow: isDragging
      ? '0 4px 8px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.1)'
      : '0 1px 3px rgba(0, 0, 0, 0.1)',
    background: isDragging ? '#353C58' : '#242830',
    borderRadius: '8px',
    padding: '5px',
    marginBottom: '4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'grab',
  };
  const handleStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'grab',
    padding: '4px',
  };

  const dotStyle = {
    width: '6px',
    height: '6px',
    backgroundColor: '#353C58',
    borderRadius: '50%',
    margin: '3px 0',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="touch-none"
    >
      <div style={handleStyle}>
        {/* Indikátor ve formě teček */}
        <div style={dotStyle}></div>
        <div style={dotStyle}></div>
        <div style={dotStyle}></div>
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
};

export default SortableItem;
