import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';

const SortableGallery = ({ id, photo, onDelete, onEdit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="gallery-item relative"
    >
      <img
        src={`${API_URL}/img/galleries/${photo.imageName}`}
        alt="Gallery"
        className="gallery-image"
      />
      <div className="absolute bottom-2 right-2 flex space-x-2">
        <CiEdit
          style={{
            color: 'red',
            fontSize: '40px',
            backgroundColor: 'white',
            borderRadius: '20%',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          onPointerDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <RiDeleteBin6Line
          className="text-white cursor-pointer hidden"
          onClick={() => onDelete(photo._id)}
        />
      </div>
    </div>
  );
};

export default SortableGallery;
