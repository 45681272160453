// src/components/ConfirmDeleteItemModal.js
import React from 'react';
import { motion } from 'framer-motion';
import { useTranslate } from '@tolgee/react';

const ConfirmDeleteItemModal = ({ confirmDeleteItem, cancelDeleteItem }) => {
  const { t } = useTranslate();

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col items-center ring-1 ring-buttonBlue/30 p-10 rounded-lg bg-zinc-100 backdrop-filter backdrop-blur-md bg-opacity-10"
      >
        <h1 className="text-white font-bold text-xl mb-3">
          {t('delete_post')}
        </h1>
        <p className="text-white text-lg"> {t('delete_post_prompt')}</p>
        <div className="flex justify-center mt-8">
          <button
            onClick={confirmDeleteItem}
            className="relative mr-4 inline-block p-px font-semibold leading-6 text-white no-underline shadow-2xl cursor-pointer group rounded-lg shadow-zinc-900"
          >
            <span className="absolute inset-0 overflow-hidden rounded-xl">
              <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
            </span>
            <div className="relative z-10 text-md flex items-center px-3 py-2 space-x-2 rounded-lg bg-buttonBlue">
              <p> {t('yes')}</p>
            </div>
            <span className="absolute -bottom-0 left-[1.125rem)] transition-opacity duration-500 group-hover:opacity-40"></span>
          </button>
          <button className="text-white" onClick={cancelDeleteItem}>
            {t('no')}
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default ConfirmDeleteItemModal;
