import React, { Fragment } from 'react';
import CategoryButtons from './CategoryButtons';

const CategoryDetail = ({
  item,
  index,
  category,
  handleEditCategory,
  showDeleteConfirmationDialog,
}) => {
  return (
    <Fragment>
      <div className="flex flex-row items-center justify-between text-center w-full">
        <p className="text-sm text-white truncate w-20 sm:w-24 xl:w-44">
          {category?.title
            ? category.title.length > 20
              ? `${category.title.slice(0, 20)}...`
              : category.title
            : ''}
        </p>
        <p className="text-white text-sm truncate py-2 w-20 sm:w-24 xl:w-44">
          {category?.slug
            ? category.slug.length > 20
              ? `${category.slug.slice(0, 20)}...`
              : category.slug
            : ''}
        </p>
        <div className="w-auto">
          <CategoryButtons
            category={category}
            handleEditCategory={handleEditCategory}
            showDeleteConfirmationDialog={showDeleteConfirmationDialog}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CategoryDetail;
