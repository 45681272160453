import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const usePosts = (API_URL) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/posts?sort=date&order=desc`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      if (response.data && response.data.data && response.data.data.posts) {
        setPosts(response.data.data.posts);
      } else {
        setPosts([]);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setIsLoading(false);
  }, [API_URL]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return { posts, isLoading, fetchPosts, setIsLoading, setPosts };
};

export default usePosts;
