import React, { useEffect, useState, useRef, Fragment } from 'react';
import axios from 'axios';
import { useTranslate } from '@tolgee/react';

import { motion, AnimatePresence } from 'framer-motion';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode
import { ClipLoader } from 'react-spinners'; // Import ClipLoader from react-spinners
import { IoCloseOutline } from 'react-icons/io5';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import usePosts from './usePosts';
import { useCategoryContext } from '../../components/CategoryContext';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SortableItem from './SortableItem';
import CategoryDetail from './CategoryDetail';
import ConfirmDeleteCategoryModal from './ConfirmDeleteCategoryModal';

const Category = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslate();
  const { isLoading, setIsLoading } = usePosts(API_URL);
  const jwt = localStorage.getItem('jwt');

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [showRightDiv, setShowRightDiv] = useState(false);
  const rightDivRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [sortBy, setSortBy] = useState('desc');

  const [isDescending, setIsDescending] = useState(false);
  const { categories, setCategories } = useCategoryContext();
  const [categoryId, setCategoryId] = useState('');

  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    icon: '',
    tenantIds: [],
  });

  // Sensors for dnd-kit
  const sensors = useSensors(
    useSensor(PointerSensor), //Desktop and touchable screens
    useSensor(KeyboardSensor),
    useSensor(TouchSensor) //Smoother support on mobile devices
  );

  const [priceLists, setPriceLists] = useState([]);

  const handleRightDivOpen = () => {
    setShowRightDiv(true);
    setIsEditing(false);
    setFormData({
      // Reset the form to empty values
      title: '',
      slug: '',
      icon: '',
      tenantIds: '',
    });
  };

  const handleRightDivClose = () => {
    setShowRightDiv(false);
    resetForm(); // Reset the form data when closing the panel
  };
  // Click outside div to close
  useEffect(() => {
    function handleClickOutside(event) {
      if (rightDivRef.current && !rightDivRef.current.contains(event.target)) {
        setShowRightDiv(false);
        handleRightDivClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const resetForm = () => {
    setIsEditing(false);
  };

  const [tenants, setTenants] = useState([]); // Stav pro seznam tenantů

  // Fetch tenants
  const fetchTenants = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/v1/tenants`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.tenants)
      ) {
        setTenants(response.data.data.tenants); // Uložíme seznam tenantů do stavu
      } else {
        console.error('Invalid API response format for tenants.');
      }
    } catch (error) {
      console.error('Error fetching tenants:', error);
    }
  };

  // Volání funkce fetchTenants při prvním načtení pravého divu
  useEffect(() => {
    if (showRightDiv) {
      fetchTenants();
    }
  }, [showRightDiv]);

  // Fetch pricelist
  const fetchCategories = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/categories`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.data &&
        response.data.data.categories
      ) {
        const categories = response.data.data.categories;

        // Filter only validate items with ._id
        const validCategories = categories.filter(
          (category) => category && category._id
        );

        // Sort by order
        const sortedCategories = validCategories.sort(
          (a, b) => a.order - b.order
        );

        // Save to state
        setCategories(sortedCategories);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle drag end
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return; // Ensure that both IDs are defined
    if (active.id !== over.id) {
      setCategories((currentCategories) => {
        const validCategories = currentCategories.filter(
          (category) => category && category._id
        ); // Filter
        const oldIndex = validCategories.findIndex(
          (category) => category._id === active.id
        );
        const newIndex = validCategories.findIndex(
          (category) => category._id === over.id
        );

        if (oldIndex === -1 || newIndex === -1) return currentCategories; // Controlling existing index

        const updatedOrder = arrayMove(validCategories, oldIndex, newIndex);
        saveOrderToBackend(updatedOrder);
        return updatedOrder;
      });
    }
  };

  const saveOrderToBackend = async (updatedOrder) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }

    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;

    try {
      await axios.put(
        `${API_URL}/api/v1/tenants/${tenantId}/categories/order`,
        { categories: updatedOrder.map((category) => category._id) },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error saving order to backend:', error);
    }
  };

  // delete priceList
  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem('jwt');

    if (!token) {
      console.error('No JWT token found');
      return;
    }
    try {
      const decodedToken = jwtDecode(token);

      const tenantId = decodedToken.tenantId;
      await axios.delete(
        `${API_URL}/api/v1/tenants/${tenantId}/categories/${categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );

      setCategories((prev) =>
        prev.filter((category) => category._id !== categoryId)
      );
      toast.success('Category deleted successfully.');
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Chyba při odstraňování cenového seznamu:', error);
      toast.error('Failed to delete item.');
    }
  };

  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };

  const showDeleteConfirmationDialog = (categoryId) => {
    setCategoryToDelete(categoryId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteCategory = async () => {
    setIsLoading(true); // Set loading to true when starting the request
    await handleDeleteCategory(categoryToDelete);
    setIsLoading(false); // Set loading to false when request is complete
    setShowDeleteConfirmation(false);
  };

  const cancelDeleteCategory = () => {
    setShowDeleteConfirmation(false);
  };

  const handleEditCategory = async (categoryId) => {
    setIsEditing(true);
    setShowRightDiv(true);
    setCategoryId(categoryId); // Set correct ID

    try {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('No JWT token found');
        setIsLoading(false);
        return;
      }
      const decodedToken = jwtDecode(token);
      const tenantId = decodedToken.tenantId;
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/categories/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (response.data && response.data.data && response.data.data.category) {
        const { title, slug, icon, tenantIds } = response.data.data.category;

        // Nastav formData s daty z API
        setFormData({
          title: title || '',
          slug: slug || '',
          icon: icon || '',
          tenantIds: tenantIds || [],
        });
      } else {
        console.error('Category not found in API response.');
      }
    } catch (error) {
      console.error('Error fetching category:', error);
    }
  };

  const handleSortPriceLists = async (order) => {
    setIsLoading(true); // Set loading to true when starting the request

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const tenantId = decodedToken.tenantId;
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists?sort=date&order=desc`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setPriceLists(response.data.data.priceLists);
      setSortBy(order);
    } catch (error) {
      console.error('Error sorting posts:', error);
    }
    setIsLoading(false);
  };

  const sortByTitle = () => {
    let sortedPriceLists = [...priceLists];
    if (!isDescending) {
      sortedPriceLists.sort((a, b) => a.itemName.localeCompare(b.itemName));
    } else {
      sortedPriceLists.sort((a, b) => b.itemName.localeCompare(a.itemName));
    }
    setPriceLists(sortedPriceLists);
    setIsDescending(!isDescending);
  };
  // Submit form
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;
    const method = isEditing ? 'PATCH' : 'POST';
    const url = isEditing
      ? `${API_URL}/api/v1/tenants/${tenantId}/categories/${categoryId}`
      : `${API_URL}/api/v1/tenants/${tenantId}/categories`;

    try {
      const response = await axios(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        data: formData,
      });

      toast.success(
        isEditing
          ? 'Category updated successfully.'
          : 'Category added successfully.'
      );
      setShowRightDiv(false);
      fetchCategories();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to save changes.');
    }
  };

  return (
    <div className="flex flex-col md:gap-6 md:items-center relative items-center bg-newBackground rounded-lg mx-auto h-auto">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={3000}
        hideProgressBar
      />

      <div className="flex flex-col gap-6 md:items-start items-center relative bg-container xl:w-auto w-full  rounded-lg p-5 h-auto xl:p-10">
        <AnimatePresence>
          {showDeleteConfirmation && (
            <ConfirmDeleteCategoryModal
              confirmDeleteCategory={confirmDeleteCategory}
              cancelDeleteCategory={cancelDeleteCategory}
            />
          )}
        </AnimatePresence>
        {isLoading ? (
          <div className="flex justify-center items-center bg-container rounded-lg p-10 mx-auto xl:w-3/4 w-full">
            <ClipLoader color={'#123abc'} loading={isLoading} size={50} />
          </div>
        ) : (
          <Fragment>
            <div className="">
              <div className="flex flex-row justify-between items-center gap-6 text-white">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <div className="flex flex-row items-center justify-between w-full pb-5">
                      <div
                        onClick={sortByTitle}
                        className="flex flex-row gap-2 items-center justify-center cursor-pointer w-16 md:w-24 xl:w-48 text-center"
                      >
                        <h1 className="font-bold text-white text-sm">
                          {t('title')}
                        </h1>
                      </div>
                      <div className="flex flex-row font-bold text-white text-sm w-16 md:w-24 xl:w-48 items-center justify-center text-center">
                        <h1>{t('path_category')}</h1>
                      </div>
                      {/* <div className="flex flex-row gap-2 w-16 md:w-24 xl:w-48 items-center justify-center text-center">
                        <h1 className="font-bold text-white text-sm">
                          {t('tenants_category')}
                        </h1>
                      </div> */}

                      <div className="flex flex-row w-16 md:w-24 xl:w-48 gap-4 items-center justify-center text-center">
                        <button
                          onClick={handleRightDivOpen}
                          className=" inline-block font-semibold leading-6 px-3 py-1 md:px-3 md:py-2 bg-buttonBlue text-xl md:text-sm z-10 text-white no-underline  cursor-pointer rounded-md md:rounded-xl md:mb-0"
                        >
                          <span className="block md:hidden">+</span>
                          <span className="hidden md:block">
                            + {t('new_category')}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <hr className="h-0.5  w-full  border-t-0 bg-neutral-100 dark:bg-white/10" />
                    </div>
                    <div className="flex flex-col gap-2 items-start my-2">
                      {categories.length === 0 ? (
                        <p className="text-white text-sm">
                          No categories available
                        </p>
                      ) : (
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                        >
                          <SortableContext
                            items={categories
                              .filter((category) => category && category._id)
                              .map((category) => category._id)}
                            strategy={verticalListSortingStrategy}
                          >
                            {categories
                              .filter((category) => {
                                return category && category._id;
                              })
                              .map((category) => (
                                <SortableItem
                                  key={category._id}
                                  item={category}
                                >
                                  <CategoryDetail
                                    category={category}
                                    setCategoryId={setCategoryId}
                                    handleEditCategory={handleEditCategory}
                                    showDeleteConfirmationDialog={
                                      showDeleteConfirmationDialog
                                    }
                                  />
                                </SortableItem>
                              ))}
                          </SortableContext>
                        </DndContext>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {/* right side */}
        <AnimatePresence>
          {showRightDiv && (
            <div className="fixed inset-0 flex pt-5 h-auto justify-center bg-black bg-opacity-50 z-50">
              <motion.div
                ref={rightDivRef}
                className="absolute sm:right-3 sm:top-10 flex flex-col gap-6 bg-container h-auto rounded-lg p-5 w-full sm:w-[600px] md:w-[700px] max-w-[90%] sm:max-w-none"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex justify-between">
                  <div className="flex flex-row justify-start items-center gap-2 text-white">
                    <IoCloseOutline
                      className="hover:text-buttonViolet cursor-pointer text-xl"
                      onClick={() => {
                        handleRightDivClose();
                      }}
                    />
                    <h1>
                      {' '}
                      {isEditing ? t('edit_category') : t('new_category')}
                    </h1>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="relative inline-block p-px font-semibold text-white no-underline bg-buttonGreen shadow-2xl cursor-pointer group rounded-lg shadow-zinc-900"
                  >
                    <span className="absolute inset-0 overflow-hidden rounded-xl">
                      <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                    </span>
                    <div className="relative z-10 text-sm flex items-center px-3 py-2 space-x-2 rounded-xl">
                      {isEditing ? t('update') : t('save')}
                    </div>
                    <span className="absolute -bottom-0 left-[1.125rem)] transition-opacity duration-500 group-hover:opacity-40"></span>
                  </button>
                </div>
                <div>
                  <hr className="h-0.5  w-full border-t-0 bg-neutral-100 dark:bg-white/10" />
                </div>
                <div>
                  {/* title */}
                  <h1 className="text-lineGrey pb-2">
                    {t('title')} <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                    name="title"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  ></input>
                  {/* slug */}
                  <h1 className="text-lineGrey py-2">
                    {t('path_category')}{' '}
                    <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    name="slug"
                    value={formData.slug}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        slug: e.target.value,
                      })
                    }
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                  ></input>
                  {/* icon */}
                  <h1 className="text-lineGrey py-2">
                    {t('icon_category')}{' '}
                    <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    name="icon"
                    value={formData.icon}
                    onChange={(e) =>
                      setFormData({ ...formData, icon: e.target.value })
                    }
                    className="no-arrows rounded-lg bg-newBackground text-white py-2 w-full px-4"
                  ></input>

                  {/* tenantIds */}
                  <div>
                    <h1 className="text-lineGrey py-2">
                      {t('tenants_category')}{' '}
                      <span className="text-red-500 ml-1">*</span>
                    </h1>
                    <div className="flex flex-col gap-2">
                      {tenants.map((tenant) => (
                        <label
                          key={tenant._id} // Použití _id jako unikátní klíč v Reactu
                          className="flex items-center gap-2 text-white"
                        >
                          <input
                            type="checkbox"
                            value={tenant.tenantId} // Použití správného tenantId místo _id
                            checked={formData.tenantIds.includes(
                              tenant.tenantId
                            )} // Kontrola správného zaškrtnutí
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFormData((prevFormData) => {
                                const updatedTenantIds = isChecked
                                  ? [
                                      ...new Set([
                                        ...prevFormData.tenantIds,
                                        tenant.tenantId,
                                      ]),
                                    ] // Přidání bez duplicit
                                  : prevFormData.tenantIds.filter(
                                      (id) => id !== tenant.tenantId
                                    ); // Odebrání tenantId
                                return {
                                  ...prevFormData,
                                  tenantIds: updatedTenantIds,
                                };
                              });
                            }}
                          />
                          {tenant.tenantName}, {tenant.tenantId}{' '}
                          {/* Zobrazení názvu a tenantId */}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Category;
