import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('jwt'));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!token) return;

      const decodedToken = jwtDecode(token);
      const tenantId = decodedToken.tenantId;

      try {
        const response = await axios.get(
          `${API_URL}/api/v1/tenants/${tenantId}/categories`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data?.data?.categories) {
          setCategories(
            response.data.data.categories.sort((a, b) => a.order - b.order)
          );
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [token]); // Sledujeme změny stavu `token`

  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('jwt')); // Aktualizuje stav tokenu
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <CategoryContext.Provider value={{ categories, isLoading, setCategories }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategoryContext = () => useContext(CategoryContext);
