import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslate } from '@tolgee/react';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { motion, AnimatePresence } from 'framer-motion';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode
import axios from 'axios'; // Import Axios
import { ClipLoader } from 'react-spinners'; // Import ClipLoader from react-spinners
import { IoCloseOutline } from 'react-icons/io5';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { IoCloudUploadOutline } from 'react-icons/io5';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import EmployeeDetail from './EmployeeDetail';
import ConfirmDeleteEmployeeModal from './ConfirmDeleteEmployeeModal';
import useEmployees from './useEmployees';

const initialFormData = {
  name: '',
  signature: '',
  description: '',
  imageName: null,
};

function Employee() {
  // const API_URL = process.env.REACT_APP_API_URL;
  const jwt = localStorage.getItem('jwt');
  const API_URL = process.env.REACT_APP_API_URL;

  const { employees, isLoading, setIsLoading, setEmployees, fetchEmployees } =
    useEmployees(API_URL);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [showRightDiv, setShowRightDiv] = useState(false);
  const rightDivRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imageChange, setImageChange] = useState(false);
  const [imageName, setImageName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [employeeId, setEmployeeId] = useState(['']);
  const [showImageInput, setShowImageInput] = useState(false);

  const [isDescending, setIsDescending] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);

  const { t } = useTranslate();
  const [formData, setFormData] = useState(initialFormData);

  const handleRightDivOpen = () => {
    setShowRightDiv(true);
  };

  const handleRightDivClose = () => {
    setShowRightDiv(false);
    resetForm(); // Reset the form data when closing the panel
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (rightDivRef.current && !rightDivRef.current.contains(event.target)) {
        setShowRightDiv(false);
        handleRightDivClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeImage = () => {
    setImageUrl('');
    setImageChange(true);
    setShowImageInput(true);
    setFormData({ ...formData, imageName: '' });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      // Save the current image to oldImageName before making changes
      setImageUrl(imageUrl);
      setImageName(file.name);
      setFormData({ ...formData, imageName: file });
      setShowImageInput(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Získá název pole a novou hodnotu
    setFormData((prevData) => ({
      ...prevData, // Zachová předchozí hodnoty
      [name]: value, // Aktualizuje pouze příslušný klíč
    }));
  };

  const handleSortEmployees = async (order) => {
    setIsLoading(true); // Set loading to true when starting the request

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/employees`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.data && response.data.data.employees) {
        setEmployees(response.data.data.employees);
      }
    } catch (error) {
      console.error('Error sorting posts:', error);
    }
    setIsLoading(false);
  };

  const sortByTitle = () => {
    let sortedEmployees = [...employees];
    if (!isDescending) {
      sortedEmployees.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedEmployees.sort((a, b) => b.name.localeCompare(a.name));
    }
    setEmployees(sortedEmployees);
    setIsDescending(!isDescending);
  };

  const handleEditEmployee = async (employeeId) => {
    handleRightDivOpen();
    setIsEditing(true);

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }
    try {
      const decodedToken = jwtDecode(token);

      const tenantId = decodedToken.tenantId;

      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/employees/${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      const formData = await response.data;

      // Get the image URL from formData
      const imageUrl = `${API_URL}/img/employees/${formData.data.employee.imageName}`;
      setImageUrl(imageUrl); // Set URL of image (if existing)

      // Set state formData with data

      setFormData({
        name: formData.data.employee.name,
        description: formData.data.employee.description,
        signature: formData.data.employee.signature,
        imageName: formData.data.employee.imageName,
      });

      // Set isEditing to true and postId to the ID of the post being edited
      setEmployeeId(formData.data.employee._id);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, signature, description, imageName } = formData;

    if (!name || !signature || !description || !imageName) {
      toast.error('Please fill in all fields.');
      return;
    }

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }
    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;
    const method = isEditing ? 'PATCH' : 'POST';
    const url = isEditing
      ? `${API_URL}/api/v1/tenants/${tenantId}/employees/${employeeId}`
      : `${API_URL}/api/v1/tenants/${tenantId}/employees`;

    try {
      // Prepare data for update or submit
      const postData = new FormData();
      postData.append('name', formData.name);
      postData.append('signature', formData.signature);
      postData.append('description', formData.description);
      // postData.append('slug', formData.slug); // Add slug to request

      if (formData.imageName) {
        postData.append('image', formData.imageName);
      }

      const response = await axios(url, {
        method,
        headers: {
          'Content-Type': 'multipart/form-data',

          Authorization: `Bearer ${token}`,
        },
        data: postData,
      });

      toast.success(
        isEditing
          ? 'Employee updated successfully!'
          : 'Employee created successfully!'
      );

      // Reset the form
      setFormData({
        name: '',
        signature: '',
        description: '',
        imageName: null,
      });

      handleRightDivClose();
      fetchEmployees();
    } catch (error) {
      console.error(
        'Error creating/updating employee:',
        error.response?.data?.message || error.message
      );
      toast.error('Error creating/updating employee.');
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsEditing(false);
    setImageUrl('');
    setImageChange(false);
    setShowImageInput(false);
    setImageName(false);
  };

  const handleDeleteEmployee = async (employeeId) => {
    const token = localStorage.getItem('jwt');
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    if (!token) {
      console.error('No JWT token found');
      return;
    }
    try {
      // Get data of employees
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/employees/${employeeId}`,
        {
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      const newId = response.data;

      if (newId) {
        const employee = newId.data.employee;

        const imageName = employee.imageName;
        // Delete an image, if existing

        await axios.delete(
          `${API_URL}/api/v1/employees/deleteImg/${imageName}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Delete employee
        await axios.delete(
          `${API_URL}/api/v1/tenants/${tenantId}/employees/${employeeId}`,
          {
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        reset();
        // Updating the employee list
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee._id !== employeeId)
        );

        toast.success('Employee deleted successfully.');
      }
    } catch (error) {
      console.error('Error Response:', error.response?.data);
      toast.error('Failed to delete employee.');
    }
  };
  const [seed, setSeed] = useState(1);

  const reset = () => {
    setSeed(Math.random());
  };
  const showDeleteConfirmationDialog = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteEmployee = async () => {
    setIsLoading(true); // Set loading to true when starting the request
    await handleDeleteEmployee(employeeToDelete);
    setIsLoading(false); // Set loading to false when request is complete
    setShowDeleteConfirmation(false);
  };

  const cancelDeleteEmployee = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="flex flex-col md:gap-6 md:items-center relative items-center bg-newBackground rounded-lg mx-auto h-auto">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={3000}
        hideProgressBar
      />
      <div className="flex flex-col gap-6 md:items-start items-center relative bg-container xl:w-auto w-full  rounded-lg p-5 h-auto xl:p-10">
        <AnimatePresence>
          {showDeleteConfirmation && (
            <ConfirmDeleteEmployeeModal
              confirmDeleteEmployee={confirmDeleteEmployee}
              cancelDeleteEmployee={cancelDeleteEmployee}
            />
          )}
        </AnimatePresence>
        {isLoading ? (
          <div className="flex justify-center items-center bg-container rounded-lg p-10 mx-auto xl:w-3/4 w-full">
            <ClipLoader color={'#123abc'} loading={isLoading} size={50} />
          </div>
        ) : (
          <Fragment>
            <div className="">
              <div className="flex flex-row justify-between items-center gap-6 text-white">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <div className="flex flex-row items-center justify-between w-full pb-5">
                      <div
                        onClick={sortByTitle}
                        className="flex flex-row gap-2 items-center justify-center cursor-pointer w-24 xl:w-44 text-center "
                      >
                        <h1 className="font-bold text-white text-sm ">
                          {t('name')}
                        </h1>
                        {isDescending ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </div>
                      <div className="flex flex-row font-bold text-white text-sm w-24 xl:w-44 items-center justify-center text-center">
                        <h1>{t('description')}</h1>
                      </div>
                      <div className="flex flex-row gap-2 w-24 xl:w-44 items-center justify-center text-center">
                        <h1
                          onClick={() => handleSortEmployees('desc')}
                          className="font-bold text-white text-sm cursor-pointer"
                        >
                          {t('signature')}
                        </h1>
                      </div>
                      <div className="flex flex-row w-24 xl:w-44 gap-4 items-center justify-center text-center">
                        <button
                          onClick={handleRightDivOpen}
                          className="inline-block font-semibold leading-6 px-3 py-1  md:px-3 md:py-2 bg-buttonBlue text-xl md:text-sm z-10 text-white no-underline  cursor-pointer rounded-md md:rounded-xl md:mb-0"
                        >
                          <span className="block md:hidden">+</span>
                          <span className="hidden md:block">
                            + {t('new_employee')}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <hr className="h-0.5  w-full  border-t-0 bg-neutral-100 dark:bg-white/10" />
                    </div>
                    <div className="flex flex-col gap-2 items-start my-2">
                      {employees.length === 0 ? (
                        <p className="text-white text-sm">
                          No employees available
                        </p>
                      ) : (
                        employees.map((employee) => (
                          <EmployeeDetail
                            key={employee._id}
                            employee={employee}
                            handleEditEmployee={handleEditEmployee}
                            showDeleteConfirmationDialog={
                              showDeleteConfirmationDialog
                            }
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {/* right side */}
        <AnimatePresence>
          {showRightDiv && (
            <div className="fixed inset-0 flex pt-5 h-auto justify-center bg-black bg-opacity-50 z-50">
              <motion.div
                ref={rightDivRef}
                className="absolute sm:right-3 sm:top-10 flex flex-col gap-6 bg-container h-auto rounded-lg p-5 w-full sm:w-[600px] md:w-[700px] max-w-[90%] sm:max-w-none"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex justify-between">
                  <div className="flex flex-row justify-start items-center gap-2 text-white">
                    <IoCloseOutline
                      className="hover:text-buttonViolet cursor-pointer text-lg"
                      onClick={() => {
                        handleRightDivClose();
                      }}
                    />
                    <h1>
                      {isEditing ? t('edit_employee') : t('new_employee')}
                    </h1>
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="relative inline-block p-px font-semibold text-white no-underline bg-buttonGreen shadow-2xl cursor-pointer group rounded-lg shadow-zinc-900"
                  >
                    <span className="absolute inset-0 overflow-hidden rounded-xl">
                      <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                    </span>
                    <div className="relative z-10 text-sm flex items-center px-3 py-2 space-x-2 rounded-xl">
                      {isEditing ? t('update') : t('save')}
                    </div>
                    <span className="absolute -bottom-0 left-[1.125rem)] transition-opacity duration-500 group-hover:opacity-40"></span>
                  </button>
                </div>
                <div>
                  <hr className="h-0.5  w-full border-t-0 bg-neutral-100 dark:bg-white/10" />
                </div>
                <div>
                  {/* title */}
                  <h1 className="text-lineGrey pb-2">
                    {t('name')} <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                    placeholder=""
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  ></input>
                  {/* signature */}
                  <h1 className="text-lineGrey py-2">
                    {t('signature')}{' '}
                    <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    name="signature"
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                    value={formData.signature}
                    onChange={handleInputChange}
                  ></input>
                  {/* image */}
                  <div className="form-group pt-2">
                    <label htmlFor="image" className="text-lineGrey ">
                      {t('image')} <span className="text-red-500 ml-1">*</span>
                    </label>
                    {imageUrl && !showImageInput ? (
                      <div className="flex items-center justify-start py-2 bg-newBackground">
                        <img
                          src={imageUrl}
                          alt="Employee"
                          className="w-28 h-28 p-3 object-cover"
                        />
                        <button
                          type="button"
                          className="text-black absolute top-[370px] left-[95px] m-2 cursor-pointer"
                          onClick={closeImage}
                        >
                          <div>
                            <IoMdCloseCircleOutline
                              style={{
                                color: 'red',
                                fontSize: '30px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                              }}
                            />
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-start bg-newBackground rounded-lg mt-2">
                        {!imageName && (
                          <label
                            htmlFor="image"
                            className="text-white p-3 flex flex-col items-center justify-center"
                          >
                            <IoCloudUploadOutline
                              style={{
                                color: '#B7B7B7',
                                fontSize: '60px',
                              }}
                            />
                            <p className="flex items-center text-md text-lineGrey justify-center">
                              {t('select_image')}
                            </p>
                          </label>
                        )}
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageChange}
                          required
                        />
                        {imageName && (
                          <p className="text-white mt-2">{imageName}</p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* description */}
                  <h1 className="text-lineGrey py-2">
                    {t('description')}{' '}
                    <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <textarea
                    type="text"
                    name="description"
                    rows="4" // Zvýší počet řádků na 10
                    value={formData.description}
                    onChange={handleInputChange}
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4 mb-2"
                  />
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Employee;
