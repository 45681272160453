// src/components/PostDetail.js
import React, { Fragment } from 'react';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { format } from 'date-fns';

const PostDetail = ({ post, handleEditPost, showDeleteConfirmationDialog }) => {
  return (
    <Fragment>
      <div className="flex flex-row items-center justify-between text-center w-full">
        <p className=" text-sm text-white w-28 xl:w-44">{post.title}</p>
        <p className="text-white text-sm py-2 w-28 xl:w-44">
          {post.description.length > 20
            ? `${post.description.slice(0, 15)}...`
            : post.description}
        </p>
        <p className="text-white text-sm py-2 w-28 xl:w-44">
          {format(new Date(post.date), 'd MMMM yyyy')}
        </p>
        <div className="flex flex-row gap-4 w-28 xl:w-44 items-center justify-center">
          <div className="bg-container w-7 h-7 border border-[#414144] rounded-md flex justify-center items-center">
            <MdEdit
              className="cursor-pointer text-buttonBlue"
              onClick={() => handleEditPost(post._id)}
            />
          </div>
          <div className="bg-container w-7 h-7 border border-[#414144] rounded-md flex justify-center items-center">
            <RiDeleteBin6Line
              onClick={() => showDeleteConfirmationDialog(post._id)}
              className="cursor-pointer text-logoRed"
            />
          </div>
        </div>
      </div>
      <hr className="h-0.5 w-full border-t-0 bg-neutral-100 dark:bg-white/10" />
    </Fragment>
  );
};

export default PostDetail;
