import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { jwtDecode } from 'jwt-decode';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useTranslate } from '@tolgee/react';

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  TouchSensor,
  KeyboardSensor,
  PointerSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableItem } from './SortableItem';
import SortableGallery from './SortableGallery';

const Gallery = () => {
  const { t } = useTranslate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);

  const [photos, setPhotos] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const token = localStorage.getItem('jwt');

  const fetchPhotos = async () => {
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/galleries`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // JWT token
          },
        }
      );
      setPhotos(response.data.data.photos);
    } catch (error) {
      console.error('Error fetching gallery photos:', error);
    }
  };
  useEffect(() => {
    fetchPhotos();
  }, []);

  // Set sensors
  const pointerSensor = useSensor(PointerSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10, // Minimal move in pixels
    },
  });

  const sensors = useSensors(pointerSensor, touchSensor);

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    // Pokud není `over`, ukončete funkci
    if (!over || active.id === over.id) {
      return;
    }

    if (active.id !== over.id) {
      setPhotos((prevPhotos) => {
        const oldIndex = prevPhotos.findIndex(
          (photo) => photo._id === active.id
        );
        const newIndex = prevPhotos.findIndex((photo) => photo._id === over.id);

        const newPhotos = arrayMove(prevPhotos, oldIndex, newIndex);

        // Update order in backend
        updatePhotoOrder(newPhotos);

        return newPhotos;
      });
    }
  };

  // Update photo order
  const updatePhotoOrder = async (newPhotos) => {
    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;

    try {
      await axios.put(
        `${API_URL}/api/v1/tenants/${tenantId}/galleries/order`,
        { photos: newPhotos.map((photo) => photo._id) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error updating photo order:', error);
    }
  };

  //  Delete photo
  const handleDeletePhoto = async (photoId) => {
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    try {
      await axios.delete(
        `${API_URL}/api/v1/tenants/${tenantId}/galleries/${photoId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPhotos((prevPhotos) =>
        prevPhotos.filter((photo) => photo._id !== photoId)
      );
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleFileChange = (e) => {
    setNewImageFile(e.target.files[0]);
  };

  // Edit photo
  const handleEditPhoto = async () => {
    if (!selectedPhoto || !newImageFile) {
      toast.error('Please select a photo and upload a new image.');
      return;
    }
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('image', newImageFile);

      const response = await fetch(
        `${API_URL}/api/v1/tenants/${tenantId}/galleries/${selectedPhoto}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update photo');
      }

      const data = await response.json();

      // Update gallery on frontend
      setPhotos((prevPhotos) =>
        prevPhotos.map((photo) =>
          photo._id === selectedPhoto
            ? { ...photo, imageName: data.data.photo.imageName }
            : photo
        )
      );

      setSelectedPhoto(null);
      setNewImageFile(null);

      toast.success('Photo updated successfully!');
    } catch (error) {
      console.error('Error updating photo:', error);
      toast.error('Failed to update photo.');
    } finally {
      setIsLoading(false); // Vypnutí načítání
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement]}
      sensors={sensors}
    >
      <SortableContext items={photos.map((photo) => photo._id)}>
        <div className="w-96 md:w-[640px] items-center justify-center mt-16 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 mx-auto">
          {photos.map((photo) => (
            <SortableGallery
              key={photo._id}
              id={photo._id}
              photo={photo}
              onDelete={handleDeletePhoto}
              onEdit={() => setSelectedPhoto(photo._id)}
            />
          ))}
        </div>
      </SortableContext>

      {selectedPhoto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="ring-1 ring-buttonBlue/30 bg-zinc-100 backdrop-blur-sm bg-opacity-10 rounded-lg shadow-lg p-4 w-full max-w-md sm:p-6 sm:w-1/2 lg:w-1/3 relative">
            <button
              className="absolute top-2 right-2 text-white hover:text-gray-800"
              onClick={() => setSelectedPhoto(null)}
            >
              ✕
            </button>
            {!newImageFile ? (
              <>
                <label
                  htmlFor="image"
                  className="text-black p-3 flex flex-col items-center justify-center cursor-pointer"
                >
                  <IoCloudUploadOutline
                    style={{
                      color: '#ffffff',
                      fontSize: '60px',
                    }}
                  />
                  <p className="flex text-start text-sm text-white items-center justify-center sm:text-md">
                    {t('select_image')}
                  </p>
                </label>
                <input
                  type="file"
                  className="hidden"
                  id="image"
                  name="image"
                  onChange={handleFileChange}
                />
              </>
            ) : (
              <div className="flex flex-col items-center">
                <img
                  src={URL.createObjectURL(newImageFile)}
                  alt="Selected"
                  className="w-24 h-24 object-cover rounded-lg mb-4 sm:w-32 sm:h-32"
                />
              </div>
            )}
            {isLoading && (
              <div className="flex items-center justify-center mt-4">
                <div className="loader"></div>
              </div>
            )}
            <div className="flex flex-row gap-3 items-center justify-center mt-4  sm:gap-5">
              <button
                className="scale-button bg-buttonBlue text-white px-4 py-2 rounded"
                onClick={handleEditPhoto}
                disabled={!newImageFile || isLoading}
              >
                {isLoading ? t('uploading_photo') : t('update_photo')}
              </button>
              <button
                className="scale-button bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => {
                  setSelectedPhoto(null);
                  setNewImageFile(null);
                }}
              >
                {t('cancel_photo')}{' '}
              </button>
            </div>
          </div>
        </div>
      )}
    </DndContext>
  );
};

export default Gallery;
