import React, { useEffect, useState, useRef, Fragment } from 'react';
import axios from 'axios';
import { useTranslate } from '@tolgee/react';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns'; // import format function
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode
import { ClipLoader } from 'react-spinners'; // Import ClipLoader from react-spinners
import { IoCloseOutline } from 'react-icons/io5';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { IoCloudUploadOutline } from 'react-icons/io5';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import usePosts from './usePosts'; // Importujte svůj nový hook
import ConfirmDeletePostModal from './ConfirmDeletePostModal'; // Import nové komponenty
import PostDetail from './PostDetail'; // Import nové komponenty
import PriceListDetail from './PriceListDetail';
import ConfirmDeleteItemModal from './ConfirmDeleteItemModal';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SortableItem from './SortableItem';

const PriceList = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslate();
  const { posts, isLoading, setIsLoading, setPosts, fetchPosts } =
    usePosts(API_URL);
  const jwt = localStorage.getItem('jwt');

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showRightDiv, setShowRightDiv] = useState(false);
  const rightDivRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [priceListId, setPriceListId] = useState(['']);
  const [sortBy, setSortBy] = useState('desc');
  const [open, setOpen] = useState(false);

  const [isDescending, setIsDescending] = useState(false);
  const [itemId, setItemId] = useState('');
  const [items, setItems] = useState([]);

  const [formData, setFormData] = useState({
    itemName: '',
    itemDescription: '',
    itemDuration: '',
    itemPrice: '',
  });

  // Sensors for dnd-kit
  const pointerSensor = useSensor(PointerSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(pointerSensor, touchSensor);

  const [priceLists, setPriceLists] = useState([]);

  const handleRightDivOpen = () => {
    setShowRightDiv(true);
    setIsEditing(false);
    setFormData({
      // Reset the form to empty values
      itemName: '',
      itemDescription: '',
      itemDuration: '',
      itemPrice: '',
    });
  };

  const handleRightDivClose = () => {
    setShowRightDiv(false);
    resetForm(); // Reset the form data when closing the panel
  };
  // Click outside div to close
  useEffect(() => {
    function handleClickOutside(event) {
      if (rightDivRef.current && !rightDivRef.current.contains(event.target)) {
        setShowRightDiv(false);
        handleRightDivClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const resetForm = () => {
    setIsEditing(false);
  };

  // Fetch pricelist
  const fetchPriceLists = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.data &&
        response.data.data.priceLists
      ) {
        const priceLists = response.data.data.priceLists;

        // Filter only validate items with ._id
        const validPriceLists = priceLists.filter((item) => item && item._id);

        // Sort by order
        const sortedPriceLists = validPriceLists.sort(
          (a, b) => a.order - b.order
        );
        // Save to state
        setPriceLists(sortedPriceLists);
      }
    } catch (error) {
      console.error('Chyba při načítání ceníku:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPriceLists();
  }, []);

  // Handle drag end
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return; // Ensure that both IDs are defined
    if (active.id !== over.id) {
      setPriceLists((currentItems) => {
        const validItems = currentItems.filter((item) => item && item._id); // Filter
        const oldIndex = validItems.findIndex((item) => item._id === active.id);
        const newIndex = validItems.findIndex((item) => item._id === over.id);

        if (oldIndex === -1 || newIndex === -1) return currentItems; // Controlling existing index

        const updatedOrder = arrayMove(validItems, oldIndex, newIndex);
        saveOrderToBackend(updatedOrder);
        return updatedOrder;
      });
    }
  };

  const saveOrderToBackend = async (updatedOrder) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      return;
    }

    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;

    try {
      await axios.put(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists/order`,
        { priceLists: updatedOrder.map((item) => item._id) },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error('Error saving order to backend:', error);
    }
  };

  // delete priceList
  const handleDeletePriceList = async (itemId) => {
    const token = localStorage.getItem('jwt');

    if (!token) {
      console.error('No JWT token found');
      return;
    }
    try {
      const decodedToken = jwtDecode(token);

      const tenantId = decodedToken.tenantId;
      await axios.delete(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists/${itemId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );

      setPriceLists((prev) => prev.filter((item) => item._id !== itemId));
      toast.success('Item deleted successfully.');
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Chyba při odstraňování cenového seznamu:', error);
      toast.error('Failed to delete item.');
    }
  };

  const [seed, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };

  const showDeleteConfirmationDialog = (itemId) => {
    setItemToDelete(itemId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteItem = async () => {
    setIsLoading(true); // Set loading to true when starting the request
    await handleDeletePriceList(itemToDelete);
    setIsLoading(false); // Set loading to false when request is complete
    setShowDeleteConfirmation(false);
  };

  const cancelDeleteItem = () => {
    setShowDeleteConfirmation(false);
  };

  const handleEditPriceList = async (itemId) => {
    setIsEditing(true);
    setShowRightDiv(true);
    setItemId(itemId); // Set correct ID

    try {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('No JWT token found');
        setIsLoading(false);
        return;
      }
      const decodedToken = jwtDecode(token);
      const tenantId = decodedToken.tenantId;
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      const priceList = response.data.data.priceLists[0];

      if (priceList) {
        const { itemName, itemDescription, itemDuration, itemPrice } =
          priceList;

        // Set formData
        setFormData({
          itemName: itemName || '',
          itemDescription: itemDescription || '',
          itemDuration: itemDuration || '',
          itemPrice: itemPrice || '',
        });
      } else {
        console.error('No price list found in the response');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSortPriceLists = async (order) => {
    setIsLoading(true); // Set loading to true when starting the request

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const tenantId = decodedToken.tenantId;
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/priceLists?sort=date&order=desc`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setPriceLists(response.data.data.priceLists);
      setSortBy(order);
    } catch (error) {
      console.error('Error sorting posts:', error);
    }
    setIsLoading(false);
  };

  const sortByTitle = () => {
    let sortedPriceLists = [...priceLists];
    if (!isDescending) {
      sortedPriceLists.sort((a, b) => a.itemName.localeCompare(b.itemName));
    } else {
      sortedPriceLists.sort((a, b) => b.itemName.localeCompare(a.itemName));
    }
    setPriceLists(sortedPriceLists);
    setIsDescending(!isDescending);
  };
  // Submit form
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const tenantId = decodedToken.tenantId;
    const method = isEditing ? 'PATCH' : 'POST';
    const url = isEditing
      ? `${API_URL}/api/v1/tenants/${tenantId}/priceLists/${itemId}`
      : `${API_URL}/api/v1/tenants/${tenantId}/priceLists`;

    try {
      const response = await axios(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        data: formData,
      });

      toast.success(
        isEditing ? 'Item updated successfully.' : 'Item added successfully.'
      );
      setShowRightDiv(false);
      fetchPriceLists();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to save changes.');
    }
  };

  return (
    <div className="flex flex-col md:gap-6 md:items-center relative items-center bg-newBackground rounded-lg mx-auto h-auto">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={3000}
        hideProgressBar
      />

      <div className="flex flex-col gap-6 md:items-start items-center relative bg-container xl:w-auto w-full  rounded-lg p-5 h-auto xl:p-10">
        <AnimatePresence>
          {showDeleteConfirmation && (
            <ConfirmDeleteItemModal
              confirmDeleteItem={confirmDeleteItem}
              cancelDeleteItem={cancelDeleteItem}
            />
          )}
        </AnimatePresence>
        {isLoading ? (
          <div className="flex justify-center items-center bg-container rounded-lg p-10 mx-auto xl:w-3/4 w-full">
            <ClipLoader color={'#123abc'} loading={isLoading} size={50} />
          </div>
        ) : (
          <Fragment>
            <div className="">
              <div className="flex flex-row justify-between items-center gap-6 text-white">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <div className="flex flex-row items-center justify-between w-full pb-5">
                      <div
                        onClick={sortByTitle}
                        className="flex flex-row gap-2 items-center justify-center cursor-pointer w-16 md:w-24 xl:w-48 text-center"
                      >
                        <h1 className="font-bold text-white text-sm">
                          {t('title')}
                        </h1>
                        {isDescending ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </div>
                      <div className="flex flex-row font-bold text-white text-sm w-16 md:w-24 xl:w-48 items-center justify-center text-center">
                        <h1>{t('description')}</h1>
                      </div>
                      <div className="flex flex-row gap-2 w-16 md:w-24 xl:w-48 items-center justify-center text-center">
                        <h1 className="font-bold text-white text-sm">
                          {t('pricelist_duration')}
                        </h1>
                      </div>
                      <div className="flex flex-row font-bold text-white text-sm w-16 md:w-24 xl:w-48 items-center justify-center text-center">
                        <h1>{t('pricelist_price')}</h1>
                      </div>
                      <div className="flex flex-row w-16 md:w-24 xl:w-48 gap-4 items-center justify-center text-center">
                        <button
                          onClick={handleRightDivOpen}
                          className=" inline-block font-semibold leading-6 px-3 py-1 md:px-3 md:py-2 bg-buttonBlue text-xl md:text-sm z-10 text-white no-underline  cursor-pointer rounded-md md:rounded-xl md:mb-0"
                        >
                          <span className="block md:hidden">+</span>
                          <span className="hidden md:block">
                            + {t('new_item')}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <hr className="h-0.5  w-full  border-t-0 bg-neutral-100 dark:bg-white/10" />
                    </div>
                    <div className="flex flex-col gap-2 items-start my-2">
                      {priceLists.length === 0 ? (
                        <p className="text-white text-sm">
                          No pricelist available
                        </p>
                      ) : (
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                        >
                          <SortableContext
                            items={priceLists
                              .filter((item) => item && item._id)
                              .map((item) => item._id)}
                            strategy={verticalListSortingStrategy}
                          >
                            {priceLists
                              .filter((item) => item && item._id)
                              .map((item) => (
                                <SortableItem key={item._id} item={item}>
                                  <PriceListDetail
                                    item={item}
                                    setItemId={setItemId}
                                    handleEditPriceList={handleEditPriceList}
                                    showDeleteConfirmationDialog={
                                      showDeleteConfirmationDialog
                                    }
                                  />
                                </SortableItem>
                              ))}
                          </SortableContext>
                        </DndContext>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {/* right side */}
        <AnimatePresence>
          {showRightDiv && (
            <div className="fixed inset-0 flex pt-5 h-auto justify-center bg-black bg-opacity-50 z-50">
              <motion.div
                ref={rightDivRef}
                className="absolute sm:right-3 sm:top-10 flex flex-col gap-6 bg-container h-auto rounded-lg p-5 w-full sm:w-[600px] md:w-[700px] max-w-[90%] sm:max-w-none"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex justify-between">
                  <div className="flex flex-row justify-start items-center gap-2 text-white">
                    <IoCloseOutline
                      className="hover:text-buttonViolet cursor-pointer text-xl"
                      onClick={() => {
                        handleRightDivClose();
                      }}
                    />
                    <h1>{isEditing ? t('edit_item') : t('new_item')}</h1>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="relative inline-block p-px font-semibold text-white no-underline bg-buttonGreen shadow-2xl cursor-pointer group rounded-lg shadow-zinc-900"
                  >
                    <span className="absolute inset-0 overflow-hidden rounded-xl">
                      <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                    </span>
                    <div className="relative z-10 text-sm flex items-center px-3 py-2 space-x-2 rounded-xl">
                      {isEditing ? t('update') : t('save')}
                    </div>
                    <span className="absolute -bottom-0 left-[1.125rem)] transition-opacity duration-500 group-hover:opacity-40"></span>
                  </button>
                </div>
                <div>
                  <hr className="h-0.5  w-full border-t-0 bg-neutral-100 dark:bg-white/10" />
                </div>
                <div>
                  {/* title */}
                  <h1 className="text-lineGrey pb-2">
                    {t('title')} <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="text"
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                    name="itemName"
                    value={formData.itemName}
                    onChange={(e) =>
                      setFormData({ ...formData, itemName: e.target.value })
                    }
                  ></input>
                  {/* duration */}
                  <h1 className="text-lineGrey py-2">
                    {t('pricelist_duration')}{' '}
                  </h1>
                  <input
                    type="text"
                    name="itemDuration"
                    value={formData.itemDuration}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        itemDuration: e.target.value,
                      })
                    }
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4"
                  ></input>
                  {/* price */}
                  <h1 className="text-lineGrey py-2">
                    {t('pricelist_price')}{' '}
                    <span className="text-red-500 ml-1">*</span>
                  </h1>
                  <input
                    type="number"
                    name="itemPrice"
                    value={formData.itemPrice}
                    onChange={(e) =>
                      setFormData({ ...formData, itemPrice: e.target.value })
                    }
                    className="no-arrows rounded-lg bg-newBackground text-white py-2 w-full px-4"
                  ></input>

                  {/* description */}
                  <h1 className="text-lineGrey py-2">{t('description')} </h1>
                  <textarea
                    type="text"
                    name="itemDescription"
                    value={formData.itemDescription || ''}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        itemDescription: e.target.value,
                      })
                    }
                    rows="4"
                    className="rounded-lg bg-newBackground text-white py-2 w-full px-4 mb-2"
                  />
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PriceList;
