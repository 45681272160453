import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const useEmployees = (API_URL) => {
  const [employees, setEmployees] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchEmployees = useCallback(async () => {
    setIsLoading(true);

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('No JWT token found');
      setIsLoading(false);

      return;
    }
    const decodedToken = jwtDecode(token);

    const tenantId = decodedToken.tenantId;

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/tenants/${tenantId}/employees`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.data && response.data.data.employees) {
        setEmployees(response.data.data.employees);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
    setIsLoading(false);
  }, [API_URL]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);
  return { employees, isLoading, fetchEmployees, setIsLoading, setEmployees };
};
export default useEmployees;
