import React, { Fragment } from 'react';
import PriceListButtons from './PriceListButtons';

const PriceListDetail = ({
  item,
  index,
  handleEditPriceList,
  showDeleteConfirmationDialog,
}) => {
  return (
    <Fragment>
      <div className="flex flex-row items-center justify-between text-center w-full">
        <p className="text-sm text-white truncate w-20 sm:w-24 xl:w-44">
          {item?.itemName
            ? item.itemName.length > 20
              ? `${item.itemName.slice(0, 20)}...`
              : item.itemName
            : ''}
        </p>
        <p className="text-white text-sm truncate py-2 w-20 sm:w-24 xl:w-44">
          {item?.itemDescription
            ? item.itemDescription.length > 20
              ? `${item.itemDescription.slice(0, 20)}...`
              : item.itemDescription
            : ''}
        </p>
        <p className="text-white text-sm py-2 w-20 sm:w-24 xl:w-44">
          {item?.itemDuration || ''}
        </p>
        <p className="text-white text-sm py-2 w-20 sm:w-24 xl:w-44">
          {item?.itemPrice ? `${item.itemPrice} Kč` : ''}
        </p>
        <div className="w-auto">
          <PriceListButtons
            item={item}
            handleEditPriceList={handleEditPriceList}
            showDeleteConfirmationDialog={showDeleteConfirmationDialog}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PriceListDetail;
