import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Login from './authentications/Login';
import { useTranslate } from '@tolgee/react';

import axios from 'axios';
import { LuHome } from 'react-icons/lu';
import Users from './pages/Side-menu/Users';
import Hours from './pages/Side-menu/Hours';
import Tenants from './pages/Side-menu/Tenants';
import Home from './pages/Side-menu/Home';
import ResetPassword from './authentications/ResetPassword';
import NewPassword from './authentications/NewPassword';
import ProtectedRoute from './authentications/ProtectedRoute';
import PostContent from './pages/Side-menu/PostContent';
import Signup from './authentications/Signup';

import Hero from './pages/Side-menu/Hero';
import { Outlet } from 'react-router-dom';
import { LanguageSelect } from './LanguageSelect';
import { jwtDecode } from 'jwt-decode';
import useUserEmail from './pages/Side-menu/useUserEmail';
import { UserProvider } from './components/UserContext';
import { UserContext } from './components/UserContext';
import MenuItem from './components/MenuItem';
import Configs from './pages/Side-menu/Configs';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import PriceList from './pages/Side-menu/PriceList';
import Employee from './pages/Side-menu/Employee';
import Category from './pages/Side-menu/Category';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import * as HiIcons from 'react-icons/hi2';
import * as IoIcons from 'react-icons/io5';
import * as LuIcons from 'react-icons/lu';

import { CategoryProvider } from './components/CategoryContext';
import { useCategoryContext } from './components/CategoryContext';
import Gallery from './pages/Side-menu/Gallery';

function App() {
  return (
    <CategoryProvider>
      <UserProvider>
        <Router>
          <ScrollLock />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/newpassword" element={<NewPassword />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Hero />} />
                <Route path="/posts" element={<PostContent />} />
                <Route path="/hours" element={<Hours />} />
                <Route path="/pricelist" element={<PriceList />} />
                <Route path="/employee" element={<Employee />} />
                <Route path="/category" element={<Category />} />
                <Route path="/users" element={<Users />} />
                <Route path="/tenants" element={<Tenants />} />
                <Route path="/configs" element={<Configs />} />
                <Route path="/gallery" element={<Gallery />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    </CategoryProvider>
  );
}

function ScrollLock() {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/login' ||
      location.pathname === '/signup' ||
      location.pathname === '/posts' ||
      location.pathname === '/users' ||
      location.pathname === '/tenants'
    ) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [location.pathname]);

  return null;
}

const MainLayout = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [menuOpen, setMenuOpen] = useState(false);

  const [userRole, setUserRole] = useState(null);
  const { user } = useContext(UserContext);
  const email = useUserEmail();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [currentTenantId, setCurrentTenantId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      const decodedToken = jwtDecode(token);
      setCurrentTenantId(decodedToken.tenantId); // Nastavíme aktuální tenantId
    }
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  // Logout
  const handleLogout = () => {
    localStorage.removeItem('jwt');
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;

        const fetchUserRole = async (userId) => {
          try {
            const response = await axios.get(
              `${API_URL}/api/v1/users/${userId}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
              }
            );
            if (
              response.status === 200 &&
              response.data.data &&
              response.data.data.user
            ) {
              setUserRole(response.data.data.user.role);
            } else {
              console.error('Unexpected data format:', response.data);
            }
          } catch (error) {
            console.error('Error fetching user role:', error);
          }
        };

        fetchUserRole(userId);
      } catch (error) {
        console.error('Invalid JWT token:', error);
      }
    }
  }, []);

  // Set icons
  const iconLibraries = {
    md: MdIcons,
    ai: AiIcons,
    fa: FaIcons,
    hi: HiIcons,
    io: IoIcons,
    lu: LuIcons,
  };
  const getIconComponent = (iconName) => {
    if (!iconName) return null;

    const prefix = iconName.slice(0, 2).toLowerCase();
    const library = iconLibraries[prefix];

    if (!library) {
      console.warn(`Icon prefix "${prefix}" is not supported.`);
      return null;
    }

    const Icon = library[iconName];
    if (!Icon) {
      console.error(`Icon "${iconName}" not found in library "${prefix}".`);
      return null;
    }

    return Icon;
  };

  // Fetch categories
  const { categories } = useCategoryContext();

  // Dynamic icons

  const dynamicMenuItems = categories
    .filter((category) => category.tenantIds.includes(currentTenantId))
    .map((category) => {
      const IconComponent = getIconComponent(category.icon);

      return {
        labelKey: t(category.title),
        icon: IconComponent ? <IconComponent /> : null,
        path: `/${category.slug}`,
        roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
      };
    });

  const dynamicMenuItemsWithHome = [
    {
      labelKey: t('home_item'),
      icon: <LuHome />,
      path: '/',
      roles: ['super-admin', 'admin', 'manager', 'editor', 'user'], // Přiřazení rolí
    },
    ...dynamicMenuItems, // Přidáme dynamické položky
  ];

  // polozky v menu
  // const menuItems = [
  //   {
  //     label: t('posts_item'),
  //     icon: <LuFileText />,
  //     value: 'posts',
  //     path: '/posts',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('opening_hours_item'),
  //     icon: <LuClock />,
  //     value: 'hours',
  //     path: '/hours',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('pricelist_item'),
  //     icon: <IoPricetagOutline />,
  //     value: 'pricelist',
  //     path: '/pricelist',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('employee_management_item'),
  //     icon: <FaRegUser />,
  //     value: 'employee',
  //     path: '/employee',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('test'),
  //     icon: <FaRegUser />,
  //     value: 'test',
  //     path: '/test',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('user_management_item'),
  //     icon: <AiOutlineUsergroupAdd />,
  //     value: 'users',
  //     path: '/users',
  //     roles: ['super-admin', 'admin', 'manager', 'editor', 'user'],
  //   },
  //   {
  //     label: t('tenants_item'),
  //     icon: <HiOutlineBuildingOffice2 />,
  //     value: 'tenants',
  //     path: '/tenants',
  //     roles: ['super-admin', 'admin'],
  //   },
  //   {
  //     label: t('configs_item'),
  //     icon: <HiOutlineBuildingOffice2 />,
  //     value: 'configs',
  //     path: '/configs',
  //     roles: ['super-admin', 'admin'],
  //   },
  // ];

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-newBackground">
      <div className="md:w-64 bg-container text-white py-5 flex flex-col">
        <div className="flex flex-row items-center justify-between md:justify-start gap-3 pl-5">
          {/* logo */}
          <Link to="/">
            <div className="flex flex-row gap-2 items-center justify-center">
              {' '}
              <img
                src={process.env.PUBLIC_URL + '/assets/svg/eclipse.svg'}
                alt="eclipse"
              />
              {/* logo title */}
              <button className="relative inline-block p-px font-semibold leading-6 text-white no-underline shadow-2xl cursor-pointer group rounded-lg shadow-zinc-900">
                <span className="absolute inset-0 overflow-hidden rounded-xl">
                  <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
                </span>
                <Link to="/">
                  <div className="relative z-10 text-sm flex items-center px-3 py-2 space-x-2 rounded-xl">
                    <p>ECLIPSE</p>
                  </div>
                </Link>
                <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] transition-opacity duration-500 group-hover:opacity-40"></span>
              </button>{' '}
            </div>
          </Link>
          {/* hamburger menu on mobile */}
          <button onClick={handleMenuToggle} className="md:hidden pr-5">
            {menuOpen ? (
              <AiOutlineClose size={24} />
            ) : (
              <AiOutlineMenu size={24} />
            )}
          </button>
        </div>
        <div
          className={`flex-col flex-grow ${
            menuOpen ? 'flex' : 'hidden'
          } md:flex`}
        >
          <h1 className="font-bold pb-4 pl-5 pt-3 p-2 text-miniText">
            {t('overview')}
          </h1>
          <ul className="flex flex-col px-3">
            <Link to="/" onClick={handleMenuToggle}>
              <ul
                className={`flex flex-col ${
                  menuOpen ? 'flex' : 'hidden md:flex'
                }`}
              >
                {dynamicMenuItemsWithHome.map((item) => (
                  <MenuItem key={item.path} path={item.path} icon={item.icon}>
                    {t(item.labelKey)}
                  </MenuItem>
                ))}
              </ul>
            </Link>
          </ul>

          <div className="md:mt-auto flex flex-col items-center justify-center mx-auto p-5 ">
            <LanguageSelect />
            <div className="p-4 text-white">
              <button className="text-textHover pointer-events-none bg-buttonHover text-sm px-2 py-1 rounded-xl">
                {email ? <span>{email}</span> : 'Not logged in'}
              </button>
            </div>
            <button
              onClick={handleLogout}
              className="text-textGrey text-sm hover:text-white cursor-pointer"
            >
              {t('logout')}
            </button>
          </div>
        </div>
      </div>

      {/* new colour background */}
      {/* <div
          class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          ></div>
        </div> */}
      {/* Main content */}
      <div
        className={`w-full min-h-screen bg-newBackground md:px-20 mx-auto ${
          menuOpen ? 'hidden md:block' : ''
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default App;
