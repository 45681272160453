import React from 'react';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

const CategoryButtons = ({
  category,
  handleEditCategory,
  showDeleteConfirmationDialog,
}) => {
  return (
    <div className="flex flex-row gap-4 w-20 sm:w-24 xl:w-44 items-center justify-center">
      <div
        className="bg-container w-7 h-7 border border-[#414144] rounded-md flex justify-center items-center"
        onPointerDown={(e) => e.stopPropagation()}
      >
        <MdEdit
          className="cursor-pointer text-buttonBlue"
          onClick={() => handleEditCategory(category._id)}
        />
      </div>
      <div
        className="bg-container w-7 h-7 border border-[#414144] rounded-md flex justify-center items-center"
        onPointerDown={(e) => e.stopPropagation()}
      >
        <RiDeleteBin6Line
          className="cursor-pointer text-logoRed"
          onClick={() => showDeleteConfirmationDialog(category._id)}
        />
      </div>
    </div>
  );
};

export default CategoryButtons;
